*, h1, h2, h3 {
  font-family: Roboto, sans-serif;
}

h1, p {
  margin-bottom: 15px;
}

.logo, .nav-links, nav {
  display: flex;
}

.nav-toggle-label, header, nav {
  position: relative;
}

.hamburger, .hero-content {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.category h3, .hero-content {
  text-align: center;
  padding: 20px;
}

.article, .category, .product {
  box-shadow: 0 4px 8px #0000001a;
}

.article:hover, .category:hover {
  box-shadow: 0 8px 16px #0003;
}

.criteria strong, .logo {
  font-weight: 700;
}

.amazon-btn, a {
  text-decoration: none;
}

.article-content h1, .category h3, .hero-content, .product, footer, h2 {
  text-align: center;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  color: #f4f4f4;
  background-color: #121212;
  line-height: 1.6;
}

a {
  color: inherit;
}

h1 {
  font-size: 3.5em;
}

h2 {
  color: var(--primary);
  margin-bottom: 20px;
  font-size: 2.5em;
}

h3 {
  margin-bottom: 10px;
  font-size: 1.4em;
}

p {
  color: #ddd;
}

:root {
  --primary: #e74c3c;
  --secondary: #3498db;
  --accent: #f4c542;
}

nav {
  background: linear-gradient(to right, var(--primary), var(--secondary));
  color: #fff;
  z-index: 10;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
}

.filter, .logo {
  align-items: center;
}

.logo {
  font-size: 1.8em;
}

.logo-img {
  width: auto;
  height: 40px;
  margin-right: 10px;
}

.nav-links {
  list-style: none;
}

.nav-links li {
  margin-left: 20px;
}

.nav-links a {
  color: #fff;
  font-size: 1.2rem;
  transition: color .3s;
}

.nav-links a:hover {
  color: #f0f0f0;
}

.nav-toggle {
  visibility: hidden;
  display: none;
}

.nav-toggle-label {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: none;
}

.hamburger, .hamburger:after, .hamburger:before {
  background-color: #fff;
  width: 18px;
  height: 3px;
  transition: all .3s;
  position: absolute;
}

.hamburger {
  z-index: 10001;
}

.hamburger:after, .hamburger:before {
  content: "";
  left: 0;
}

.hamburger:before {
  top: -6px;
}

.hamburger:after {
  bottom: -6px;
}

.nav-toggle:checked + .nav-toggle-label .hamburger {
  background-color: #0000;
  transform: rotate(90deg);
}

.nav-toggle:checked + .nav-toggle-label .hamburger:before {
  top: 0;
  transform: rotate(45deg);
}

.nav-toggle:checked + .nav-toggle-label .hamburger:after {
  bottom: 0;
  transform: rotate(-45deg);
}

header {
  min-height: 400px;
  overflow: hidden;
}

.hero-content {
  z-index: 2;
  width: 100%;
  max-width: 800px;
  position: absolute;
}

.article-content ul li:before, .carousel, .carousel-item, .carousel-item:before {
  position: absolute;
  top: 0;
  left: 0;
}

.hero-content h1 {
  color: #fff;
  margin-bottom: 10px;
  font-size: 2.5em;
}

.hero-content p {
  color: #fffc;
  margin-bottom: 30px;
  font-size: 1.3em;
}

.btn {
  background-color: var(--primary);
  color: #fff;
  border-radius: 5px;
  padding: 12px 24px;
  display: inline-block;
}

.btn:hover {
  background-color: darken(var(--primary), 10%);
}

.carousel {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.carousel-item {
  width: 100%;
  height: 100%;
  display: none;
}

.carousel-item img {
  object-fit: cover;
  z-index: 0;
  width: 100%;
  height: 100%;
}

.back-to-top.show, .carousel-item.active {
  display: block;
}

.carousel-dots {
  z-index: 3;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.carousel-item:before {
  content: "";
  z-index: 1;
  background-color: #00000080;
  width: 100%;
  height: 100%;
}

.dot {
  cursor: pointer;
  background-color: #ffffff80;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  display: inline-block;
}

.dot.active {
  background-color: #fff;
}

section {
  padding: 40px 0;
}

.articles, .categories {
  background-color: #181818;
}

.category-grid {
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  padding: 0 20px;
  display: grid;
}

.article, .category {
  background-color: #222;
  border-radius: 10px;
  overflow: hidden;
}

.category:hover {
  background-color: #333;
}

.article img, .category img {
  object-fit: cover;
  width: 100%;
  height: 200px;
}

.category h3 {
  color: #fff;
  margin: 0;
  font-size: 1.3em;
}

.category, .product {
  margin-top: 30px;
  margin-bottom: 30px;
}

.featured-products {
  background-color: #121212;
}

.featured-products img {
  object-fit: contain;
  max-width: 300px;
  max-height: 200px;
}

.product-carousel {
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  padding: 0 20px;
  display: flex;
  overflow-x: auto;
}

.product {
  scroll-snap-align: start;
  flex-shrink: 0;
  min-width: 300px;
  margin-right: 20px;
}

.product:last-of-type {
  margin-right: 0;
}

.product img {
  border-radius: 8px;
  max-width: 100%;
  height: auto;
  margin-bottom: 15px;
}

.article-container {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  padding: 0 20px;
  display: grid;
}

footer p {
  color: #888;
  margin-bottom: 5px;
  font-size: .9em;
}

@media (width <= 1024px) {
  .nav-links a, .nav-toggle, .nav-toggle-label {
    display: block;
  }

  .nav-links {
    background-color: #222;
    flex-direction: column;
    width: 100%;
    transition: all .3s;
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
  }

  .nav-toggle:checked + .nav-toggle-label + .nav-links {
    display: flex;
  }

  .nav-links li {
    width: 100%;
    margin: 0;
  }

  .nav-links a {
    border-bottom: 1px solid #333;
    padding: 15px 20px;
  }

  .hero-content h1 {
    font-size: 2em;
  }

  .hero-content p {
    font-size: 1em;
  }

  .category-grid {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 20px;
  }

  .category img {
    height: 150px;
  }

  .product-carousel {
    flex-direction: column;
  }

  .product {
    margin: 0 0 20px;
  }
}

.criteria-container {
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 30px;
  display: flex;
}

.criteria {
  background: #2b2b2b;
  border-radius: 10px;
  flex: 1 0 300px;
  padding: 20px;
}

.criteria h4 {
  color: #1a73e8;
  margin-bottom: 10px;
}

.criteria ul {
  margin-left: 20px;
  list-style-type: disc;
}

.criteria li {
  margin-bottom: 8px;
}

.filters-container {
  background-color: #f5f5f5;
  border-radius: 8px;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 2rem;
  padding: 1rem;
  display: flex;
}

.filter {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: .5rem 1rem;
  transition: all .3s;
  display: flex;
}

.filter:hover {
  box-shadow: 0 2px 5px #0000001a;
}

.filter-icon {
  color: #4a4a4a;
  margin-right: .5rem;
}

.filter-label {
  color: #333;
  font-weight: 500;
}

.filter-select {
  background-color: #fff;
  border: 1px solid #d0d0d0;
  border-radius: 4px;
  margin-left: .5rem;
  padding: .25rem;
}

#resetFilters {
  color: #1a73e8;
  cursor: pointer;
  background-color: #0000;
  border: 2px solid #1a73e8;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 8px 16px;
  font-size: 16px;
  transition: all .3s;
}

#resetFilters:hover {
  color: #fff;
  background-color: #1a73e8;
}

.filter-group:last-of-type {
  align-items: center;
  display: flex;
}

.product-list {
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  display: flex;
}

.product {
  background: #2b2b2b;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  width: 300px;
  padding: 20px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.product:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px #0006;
}

.product h3 {
  margin-bottom: 10px;
  font-size: 24px;
}

.product p {
  margin-bottom: 15px;
  font-size: 16px;
}

.rating {
  align-items: center;
  margin-top: 10px;
  display: flex;
}

.rating span {
  color: #f4c542;
  margin-right: 5px;
  font-size: 18px;
}

.article-content {
  color: #f4f4f4;
  background-color: #1e1e1e;
  border-radius: 10px;
  max-width: 800px;
  margin: 40px auto;
  padding: 30px;
  line-height: 1.8;
  box-shadow: 0 4px 15px #0006;
}

.amazon-btn, .back-to-top {
  color: #fff;
  cursor: pointer;
}

.article-content h1 {
  color: #e74c3c;
  margin-bottom: 20px;
  font-size: 2.5rem;
}

.article-content h2 {
  margin-top: 30px;
}

.article-content p {
  color: #ddd;
}

.article-content blockquote {
  color: #ccc;
  background-color: #2a2a2a;
  border-left: 5px solid #e74c3c;
  margin: 20px 0;
  padding: 15px 20px;
  font-style: italic;
}

.article-content blockquote p {
  color: #f4f4f4;
  margin: 0;
  font-size: 1.1rem;
}

.article-content img.article-image {
  border-radius: 10px;
  width: 45%;
  height: auto;
  margin: 20px auto;
  display: block;
  box-shadow: 0 5px 15px #0000004d;
}

.article-content ul {
  margin-left: 20px;
  padding-left: 0;
  list-style: none;
}

.article-content ul li {
  color: #f4f4f4;
  margin-bottom: 10px;
  padding-left: 25px;
  font-size: 1.1rem;
  position: relative;
}

.article-content ul li:before {
  content: "✔";
  color: #e74c3c;
  font-size: 1.2rem;
  font-weight: 700;
}

.article-content p {
  margin-bottom: 20px;
  font-size: 1.15rem;
  line-height: 1.8;
}

.article-content a {
  color: #3498db;
  text-decoration: underline;
}

.article-content a:hover {
  color: #f39c12;
}

.article-content h2 {
  color: #e74c3c;
  border-bottom: 2px solid #3498db;
  margin-bottom: 15px;
  padding-bottom: 5px;
  font-size: 2rem;
  font-weight: 700;
}

footer {
  color: #888;
  background-color: #0d0d0d;
  padding: 20px 0;
  font-size: .9rem;
}

footer a {
  color: #e74c3c;
  transition: color .3s;
}

footer a:hover {
  color: #c0392b;
}

@media (width <= 768px) {
  .article-content {
    padding: 20px;
  }

  .article-content h1 {
    font-size: 2rem;
  }

  .article-content h2 {
    font-size: 1.6rem;
  }

  .article-content p, .article-content ul li {
    font-size: 1rem;
  }

  .product-carousel {
    flex-direction: column;
  }

  .article, .product {
    margin-bottom: 20px;
  }
}

.back-to-top {
  background-color: var(--primary);
  border-radius: 50%;
  padding: 10px 15px;
  font-size: 1.5rem;
  transition: background-color .3s;
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.amazon-btn, .article, .btn, .category, .product {
  transition: transform .3s, box-shadow .3s;
}

.back-to-top:hover {
  background-color: var(--secondary);
}

.amazon-btn {
  background-color: #1a73e8;
  border: none;
  border-radius: 5px;
  align-items: center;
  min-width: 150px;
  max-width: 72%;
  margin-top: auto;
  padding: 12px 18px;
  font-weight: 700;
  display: inline-flex;
}

.amazon-btn:hover {
  background-color: #eb8900;
}

.amazon-btn svg {
  width: auto;
  height: 1em;
  margin-right: 10px;
}

.product .amazon-btn, .product .rating {
  text-align: center;
  align-self: center;
  width: 100%;
}

.amazon-btn, .btn {
  box-shadow: 0 4px 8px #0000001a;
}

.amazon-btn:hover, .btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px #0003;
}

.article:hover, .category:hover, .product:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px #0000004d;
}

.nav-toggle-label {
  transition: transform .3s;
}

.product-image {
  max-width: 300px;
  height: auto;
}

.goTop {
  opacity: 0;
  z-index: -1;
  cursor: pointer;
  background-color: #010e1bb3;
  border: none;
  border-radius: 5px;
  width: 45px;
  height: 45px;
  transition: all .4s ease-in;
  position: fixed;
  bottom: 15px;
  right: 15px;
}

.goTop .fa {
  color: #fff;
  font-size: 22px;
}

.goTop:hover {
  background-color: #010e1be6;
  opacity: 1 !important;
}

.goTop.isVisible {
  opacity: .8;
  z-index: 4;
}

.about-section {
  color: #f4f4f4;
  background-color: #1e1e1e;
  border-radius: 10px;
  margin: 2rem 0;
  padding: 3rem 2rem;
  box-shadow: 0 4px 15px #0000001a;
}

.about-section h2 {
  color: var(--primary);
  text-align: center;
  margin-bottom: 1.5rem;
  font-size: 2.5em;
}

.about-section h3 {
  color: var(--secondary);
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-size: 1.8em;
}

.about-section p {
  margin-bottom: 1.5rem;
  font-size: 1.1em;
  line-height: 1.6;
}

@media (width >= 768px) {
  .about-section {
    padding: 4rem 3rem;
  }
}
/*# sourceMappingURL=index.54e578cb.css.map */
