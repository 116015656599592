*,
h1,
h2,
h3 {
  font-family: Roboto, sans-serif
}

h1,
p {
  margin-bottom: 15px
}

.logo,
.nav-links,
nav {
  display: flex
}

.nav-toggle-label,
header,
nav {
  position: relative
}

.hamburger,
.hero-content {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}

.category h3,
.hero-content {
  padding: 20px;
  text-align: center
}

.article,
.category,
.product {
  box-shadow: 0 4px 8px rgba(0, 0, 0, .1)
}

.article:hover,
.category:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, .2)
}

.criteria strong,
.logo {
  font-weight: 700
}

.amazon-btn,
a {
  text-decoration: none
}

.article-content h1,
.category h3,
.hero-content,
.product,
footer,
h2 {
  text-align: center
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box
}

body {
  background-color: #121212;
  color: #f4f4f4;
  line-height: 1.6
}

a {
  color: inherit
}

h1 {
  font-size: 3.5em
}

h2 {
  color: var(--primary);
  margin-bottom: 20px;
  font-size: 2.5em
}

h3 {
  font-size: 1.4em;
  margin-bottom: 10px
}

p {
  color: #ddd
}

:root {
  --primary: #e74c3c;
  --secondary: #3498db;
  --accent: #f4c542
}

nav {
  background: linear-gradient(to right, var(--primary), var(--secondary));
  color: #fff;
  padding: 20px 30px;
  justify-content: space-between;
  align-items: center;
  z-index: 10
}

.filter,
.logo {
  align-items: center
}

.logo {
  font-size: 1.8em
}

.logo-img {
  height: 40px;
  width: auto;
  margin-right: 10px
}

.nav-links {
  list-style: none
}

.nav-links li {
  margin-left: 20px
}

.nav-links a {
  font-size: 1.2rem;
  color: #fff;
  transition: color .3s
}

.nav-links a:hover {
  color: #f0f0f0
}

.nav-toggle {
  display: none;
  visibility: hidden
}

.nav-toggle-label {
  display: none;
  height: 20px;
  width: 20px;
  cursor: pointer
}

.hamburger,
.hamburger::after,
.hamburger::before {
  height: 3px;
  width: 18px;
  background-color: #fff;
  position: absolute;
  transition: .3s
}

.hamburger {
  z-index: 10001
}

.hamburger::after,
.hamburger::before {
  content: '';
  left: 0
}

.hamburger::before {
  top: -6px
}

.hamburger::after {
  bottom: -6px
}

.nav-toggle:checked+.nav-toggle-label .hamburger {
  background-color: transparent;
  transform: rotate(90deg)
}

.nav-toggle:checked+.nav-toggle-label .hamburger::before {
  top: 0;
  transform: rotate(45deg)
}

.nav-toggle:checked+.nav-toggle-label .hamburger::after {
  bottom: 0;
  transform: rotate(-45deg)
}

header {
  min-height: 400px;
  overflow: hidden
}

.hero-content {
  position: absolute;
  width: 100%;
  max-width: 800px;
  z-index: 2
}

.article-content ul li:before,
.carousel,
.carousel-item,
.carousel-item::before {
  position: absolute;
  left: 0;
  top: 0
}

.hero-content h1 {
  color: #fff;
  font-size: 2.5em;
  margin-bottom: 10px
}

.hero-content p {
  color: rgba(255, 255, 255, .8);
  font-size: 1.3em;
  margin-bottom: 30px
}

.btn {
  display: inline-block;
  padding: 12px 24px;
  background-color: var(--primary);
  color: #fff;
  border-radius: 5px
}

.btn:hover {
  background-color: darken(var(--primary), 10%)
}

.carousel {
  width: 100%;
  height: 100%;
  overflow: hidden
}

.carousel-item {
  display: none;
  width: 100%;
  height: 100%
}

.carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0
}

.back-to-top.show,
.carousel-item.active {
  display: block
}

.carousel-dots {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3
}

.carousel-item::before {
  content: '';
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  z-index: 1
}

.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: rgba(255, 255, 255, .5);
  border-radius: 50%;
  cursor: pointer
}

.dot.active {
  background-color: #fff
}

section {
  padding: 40px 0
}

.articles,
.categories {
  background-color: #181818
}

.category-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  padding: 0 20px
}

.article,
.category {
  background-color: #222;
  border-radius: 10px;
  overflow: hidden
}

.category:hover {
  background-color: #333
}

.article img,
.category img {
  width: 100%;
  height: 200px;
  object-fit: cover
}

.category h3 {
  margin: 0;
  color: #fff;
  font-size: 1.3em
}

.category,
.product {
  margin-bottom: 30px;
  margin-top: 30px
}

.featured-products {
  background-color: #121212
}

.featured-products img {
  max-width: 300px;
  max-height: 200px;
  object-fit: contain
}

.product-carousel {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  padding: 0 20px
}

.product {
  min-width: 300px;
  flex-shrink: 0;
  margin-right: 20px;
  scroll-snap-align: start
}

.product:last-of-type {
  margin-right: 0
}

.product img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 15px
}

.article-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  padding: 0 20px
}

footer p {
  color: #888;
  font-size: .9em;
  margin-bottom: 5px
}

@media (max-width:1024px) {

  .nav-links a,
  .nav-toggle,
  .nav-toggle-label {
    display: block
  }

  .nav-links {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #222;
    flex-direction: column;
    transition: .3s
  }

  .nav-toggle:checked+.nav-toggle-label+.nav-links {
    display: flex
  }

  .nav-links li {
    margin: 0;
    width: 100%
  }

  .nav-links a {
    padding: 15px 20px;
    border-bottom: 1px solid #333
  }

  .hero-content h1 {
    font-size: 2em
  }

  .hero-content p {
    font-size: 1em
  }

  .category-grid {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 20px
  }

  .category img {
    height: 150px
  }

  .product-carousel {
    flex-direction: column
  }

  .product {
    margin: 0 0 20px
  }
}

.criteria-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 30px
}

.criteria {
  background: #2b2b2b;
  border-radius: 10px;
  padding: 20px;
  flex: 1 0 300px
}

.criteria h4 {
  color: #1a73e8;
  margin-bottom: 10px
}

.criteria ul {
  list-style-type: disc;
  margin-left: 20px
}

.criteria li {
  margin-bottom: 8px
}

.filters-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem;
  background-color: #f5f5f5;
  border-radius: 8px;
  margin-bottom: 2rem
}

.filter {
  display: flex;
  padding: .5rem 1rem;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  transition: .3s
}

.filter:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, .1)
}

.filter-icon {
  margin-right: .5rem;
  color: #4a4a4a
}

.filter-label {
  font-weight: 500;
  color: #333
}

.filter-select {
  margin-left: .5rem;
  padding: .25rem;
  border: 1px solid #d0d0d0;
  border-radius: 4px;
  background-color: #fff
}

#resetFilters {
  background-color: transparent;
  color: #1a73e8;
  border: 2px solid #1a73e8;
  padding: 8px 16px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: .3s;
  margin-bottom: 10px
}

#resetFilters:hover {
  background-color: #1a73e8;
  color: #fff
}

.filter-group:last-of-type {
  display: flex;
  align-items: center
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center
}

.product {
  background: #2b2b2b;
  border-radius: 10px;
  width: 300px;
  padding: 20px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center
}

.product:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, .4)
}

.product h3 {
  font-size: 24px;
  margin-bottom: 10px
}

.product p {
  font-size: 16px;
  margin-bottom: 15px
}

.rating {
  margin-top: 10px;
  display: flex;
  align-items: center
}

.rating span {
  color: #f4c542;
  font-size: 18px;
  margin-right: 5px
}

.article-content {
  max-width: 800px;
  margin: 40px auto;
  padding: 30px;
  background-color: #1e1e1e;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, .4);
  color: #f4f4f4;
  line-height: 1.8
}

.amazon-btn,
.back-to-top {
  color: #fff;
  cursor: pointer
}

.article-content h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #e74c3c
}

.article-content h2 {
  margin-top: 30px
}

.article-content p {
  color: #ddd
}

.article-content blockquote {
  margin: 20px 0;
  padding: 15px 20px;
  background-color: #2a2a2a;
  border-left: 5px solid #e74c3c;
  color: #ccc;
  font-style: italic
}

.article-content blockquote p {
  margin: 0;
  font-size: 1.1rem;
  color: #f4f4f4
}

.article-content img.article-image {
  display: block;
  width: 45%;
  height: auto;
  margin: 20px auto;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, .3)
}

.article-content ul {
  margin-left: 20px;
  list-style: none;
  padding-left: 0
}

.article-content ul li {
  position: relative;
  padding-left: 25px;
  margin-bottom: 10px;
  font-size: 1.1rem;
  color: #f4f4f4
}

.article-content ul li:before {
  content: "✔";
  color: #e74c3c;
  font-size: 1.2rem;
  font-weight: 700
}

.article-content p {
  font-size: 1.15rem;
  margin-bottom: 20px;
  line-height: 1.8
}

.article-content a {
  color: #3498db;
  text-decoration: underline
}

.article-content a:hover {
  color: #f39c12
}

.article-content h2 {
  font-weight: 700;
  color: #e74c3c;
  font-size: 2rem;
  margin-bottom: 15px;
  border-bottom: 2px solid #3498db;
  padding-bottom: 5px
}

footer {
  background-color: #0d0d0d;
  padding: 20px 0;
  color: #888;
  font-size: .9rem
}

footer a {
  color: #e74c3c;
  transition: color .3s
}

footer a:hover {
  color: #c0392b
}

@media (max-width:768px) {
  .article-content {
    padding: 20px
  }

  .article-content h1 {
    font-size: 2rem
  }

  .article-content h2 {
    font-size: 1.6rem
  }

  .article-content p,
  .article-content ul li {
    font-size: 1rem
  }

  .product-carousel {
    flex-direction: column
  }

  .article,
  .product {
    margin-bottom: 20px
  }
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: var(--primary);
  padding: 10px 15px;
  border-radius: 50%;
  font-size: 1.5rem;
  display: none;
  transition: background-color .3s
}

.amazon-btn,
.article,
.btn,
.category,
.product {
  transition: transform .3s, box-shadow .3s
}

.back-to-top:hover {
  background-color: var(--secondary)
}

.amazon-btn {
  display: inline-flex;
  align-items: center;
  padding: 12px 18px;
  background-color: #1a73e8;
  border: none;
  border-radius: 5px;
  font-weight: 700;
  min-width: 150px;
  margin-top: auto;
  max-width: 72%
}

.amazon-btn:hover {
  background-color: #eb8900
}

.amazon-btn svg {
  height: 1em;
  width: auto;
  margin-right: 10px
}

.product .amazon-btn,
.product .rating {
  align-self: center;
  text-align: center;
  width: 100%
}

.amazon-btn,
.btn {
  box-shadow: 0 4px 8px rgba(0, 0, 0, .1)
}

.amazon-btn:hover,
.btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, .2)
}

.article:hover,
.category:hover,
.product:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, .3)
}

.nav-toggle-label {
  transition: transform .3s
}

.product-image {
  max-width: 300px;
  height: auto
}

.goTop {
  border-radius: 5px;
  background-color: rgba(1, 14, 27, 0.7);
  position: fixed;
  width: 45px;
  height: 45px;
  right: 15px;
  bottom: 15px;
  border: none;
  opacity: 0;
  z-index: -1;
  transition: all 0.4s ease-in;
  cursor: pointer;
}

.goTop .fa {
  color: white;
  font-size: 22px;
}

.goTop:hover {
  opacity: 1 !important;
  background-color: rgba(1, 14, 27, 0.9);
}

.goTop.isVisible {
  opacity: 0.8;
  z-index: 4;
}

.about-section {
  background-color: #1e1e1e;
  color: #f4f4f4;
  padding: 3rem 2rem;
  margin: 2rem 0;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.about-section h2 {
  color: var(--primary);
  font-size: 2.5em;
  margin-bottom: 1.5rem;
  text-align: center;
}

.about-section h3 {
  color: var(--secondary);
  font-size: 1.8em;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.about-section p {
  font-size: 1.1em;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

@media (min-width: 768px) {
  .about-section {
      padding: 4rem 3rem;
  }
}
